@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .contents-container {
    @apply h-full mt-10 flex justify-end ;
  }

  .header-container {
    @apply h-14 bg-white flex justify-end shadow-xl
  }

  .top-sidebar-container {
    @apply bg-white h-10 shadow-xl
  }

  .active {
    @apply bg-gray-200
  }
  .form-group {
    @apply px-1 w-full space-y-6 flex mb-4
  }

  /* SELECT CUSTOM STYLES */
  .sc-select-container{
    @apply min-w-[150px] w-max h-11 border-b border-gray-400  m-auto flex justify-between items-center pl-0 pr-1 cursor-pointer text-gray-700  text-sm relative;
  }

  .sc-select-container.ol{
      @apply !border !border-gray-400 rounded-lg pl-3
  }
  .sc-select-container.ol div.sc-item-label{
    @apply bg-white px-1 
  }
  *[class^='sc-select-container-focus'].ol{
    @apply !border-2 !border-blue-600 ;
  }
  *[class^='sc-select-container-focus'].ol div.sc-item-label{
    @apply text-blue-600 ;
  }
  *[class^='sc-wrapper'] {
    @apply relative select-none;
  }
  *[class^='sc-select-container-focus'] {
    @apply border-b-2 border-blue-600;
  }
  *[class^='sc-select-container-focus'] *[class^='sc-current-item-selected-label'] {
    @apply text-blue-600;
  }
  *[class^='sc-select-container'] span{
    @apply pt-3
  }
  *[class^='sc-body'] {
    @apply z-10 shadow-lg bg-white absolute w-full mt-2 border border-gray-200;
  }
  div[class^='sc-input-wrapper'] {
    @apply p-3;
  }
  .sc-select-container.ol input[class^='sc-input']{
    @apply focus:ring-2 focus:ring-gray-600  py-1 px-3 outline-none text-gray-800 ring-1 ring-gray-400 rounded-md w-full
  }
  input[class^='sc-input']{
    @apply focus:ring-1 focus:ring-gray-400  py-1 px-3 outline-none text-gray-800 ring-1 ring-gray-400 rounded-md w-full
  }
  div[class^='sc-items-wrapper'] {
    @apply px-2 pb-2 max-h-56 overflow-y-auto;
  }
  .sc-item {
    @apply p-2 rounded-md cursor-pointer transform duration-100 text-sm text-gray-700 hover:bg-gray-200;
  }
  .sc-item-selected {
    @apply bg-gray-200 text-black;
  }
  .icon-clear-item{
    @apply cursor-pointer z-[9] hover:bg-red-700 hover:text-white hover:rounded-full
  }

 .content-title{
  @apply w-auto 
 }
 .sidebar-menu{
   @apply flex transform duration-300 space-x-2 justify-center items-center h-10 hover:bg-gray-200 px-5 text-gray-100 hover:text-gray-700
  }
  .active{
    @apply text-gray-700 bg-gray-200
  }
}