@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card-table {
    @apply bg-white border border-gray-300 shadow-sm  rounded-lg;
  }
  .card-table thead,
  .card-table tbody > tr > td {
    @apply border-gray-100;
    border-bottom: 1px solid #d7d7d7;
  }
  .card-table thead th {
    @apply text-gray-700;
  }
  .card-table tbody td {
    @apply text-gray-800;
  }
  .card-table tbody tr.row-dt {
    @apply hover:bg-gray-200;
  }
  .card-table thead th,
  .card-table tbody td {
    text-align: left;
    @apply p-3 text-sm;
  }
  .mytable tbody > tr > td {
    @apply py-1;
  }

  .page-item {
    @apply !border border-gray-300;
  }
  .page-item > a {
    @apply text-sm py-1 px-2 relative block bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:rounded-none hover:bg-gray-200 focus:shadow-none;
  }
  .page-item.active {
    @apply bg-indigo-500;
  }
  .page-item.active > a {
    @apply text-gray-200 hover:text-white hover:bg-indigo-600;
  }
  /* checkbox datatable */
  .chk-head-datatable label {
    @apply py-0;
  }

}
