@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body, html,div#root, div#root > div {
    @apply bg-gradient-to-r from-gray-200 to-blue-200;
    /* @apply bg-blue-gray-200 h-full; */
  }
}


